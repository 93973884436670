import React, { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import Content from "../../../containers/Content";
import TopBar from "../../../containers/TopBar";
import { DashboardLayout } from "../../../libs/Layout";

import { LoadingIndicator } from "../../../elements/frontend/src/components";

// Dashboard Context
const initialDashboardData = {
  company_name: "",
  address: {
    street: "",
    postal: "",
    city: "",
    country: "", // DEU
  },
  contact: {
    first_name: "",
    last_name: "",
    telephone: "",
  },
  investment: {
    categories: [],
    sub_categories: [],
    phases: [],
    region: [],
    invest: "",
    revenue: "",
    ebitda: "",
    stake: [],
    types: [],
    period: [],
    contribution: {
      strategic_investor: false,
      financial_investor: false,
      wachstums_investor: false,
    },
    horizon: {
      less_five: false,
      more_five: false,
    },
  },
  transactions: [],
  form_state: {
    initial: true,
    valid: false,
  },
  investor_content: {
    steps: [
      { label: "Überblick", stepId: "OVERVIEW" },
      { label: "Status", stepId: "STATUS" },
      { label: "Details", stepId: "DETAILS" },
    ],
    currentStep: "OVERVIEW",
    showStatus: false,
    showDetails: false,
  },
  scrollTo: "",
  detailsContentLoading: true,
};

export const DashbordContext = createContext(initialDashboardData);
export const DashboardDispatch = createContext((state) => state);

const reducerDashboard = (state, action) => {
  return { ...state, ...action.payload };
};

const Home = () => {
  const [dashboardContext, dashboardDispatch] = useReducer(reducerDashboard, initialDashboardData);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const [selectedClientId /* setSelectedClientId */] = useState(
    currentUser.selectedClient.client_id
  );

  // console.log("currentUser", currentUser);
  //console.log("dashboardContext", dashboardContext);

  const selectedClient = useMemo(() => {
    if (!currentUser.clients) return {};
    return currentUser.clients.find((client) => client.client_id === selectedClientId);
  }, [currentUser.clients, selectedClientId]);

  // console.log("selectedClient ", selectedClient);

  // for the data saved in scope "investment"
  const selectedClientInvestmentData = useMemo(() => {
    if (!selectedClient.metadata) return {};
    const scopeInvestment = selectedClient.metadata.find((item) => item.scope === "investment");
    if (scopeInvestment) {
      return scopeInvestment.data;
    }
    return {};
  }, [selectedClient.metadata]);

  // for the data saved in transactions
  const selectedClientTransactionsData = useMemo(() => {
    if (!currentUser.transactions) return [];
    const transactions = currentUser.transactions.filter(
      (item) => item.client_id === selectedClientId
    );
    if (transactions) {
      return transactions;
    }
    return [];
  }, [currentUser.transactions, selectedClientId]);

  useEffect(() => {
    if (selectedClient === undefined) return;

    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        company_name: selectedClient.name,
        contact: {
          first_name: selectedClient.contact.first_name || "",
          last_name: selectedClient.contact.last_name || "",
          telephone: selectedClient.contact.telephone || "",
        },
        address: {
          street: selectedClient.address.line_1 || "",
          postal: selectedClient.address.postal || "",
          city: selectedClient.address.city || "",
          country: selectedClient.address.country || "",
        },
        investment: selectedClientInvestmentData
          ? {
              categories: selectedClientInvestmentData.categories || [],
              sub_categories: selectedClientInvestmentData.sub_categories || [],
              phases: selectedClientInvestmentData.phases || [
                ...currentUser.datastore.phases.map((item) => item.value),
              ],
              region: selectedClientInvestmentData.region || ["DEU"],
              invest: selectedClientInvestmentData.invest || "",
              revenue: selectedClientInvestmentData.revenue || "",
              ebitda: selectedClientInvestmentData.ebitda || "",
              stake: selectedClientInvestmentData.stake || [
                ...currentUser.datastore.stake.map((item) => item.value),
              ],
              types: selectedClientInvestmentData.types || [
                ...currentUser.datastore.types.map((item) => item.value),
              ],
              period: selectedClientInvestmentData.period || [
                ...currentUser.datastore.period.map((item) => item.value),
              ],
              contribution: {
                strategic_investor:
                  selectedClientInvestmentData.contribution?.strategic_investor || false,
                financial_investor:
                  selectedClientInvestmentData.contribution?.financial_investor || false,
                wachstums_investor:
                  selectedClientInvestmentData.contribution?.wachstums_investor || false,
              },
              horizon: {
                less_five: selectedClientInvestmentData.horizon?.less_five || false,
                more_five: selectedClientInvestmentData.horizon?.more_five || false,
              },
            }
          : {},
        transactions: selectedClientTransactionsData,
      },
    });
  }, [
    currentUser.datastore.phases,
    currentUser.datastore.stake,
    currentUser.datastore.types,
    currentUser.datastore.period,
    selectedClient,
    selectedClientInvestmentData,
    selectedClientTransactionsData,
  ]);

  return (
    <>
      <DashboardLayout>
        <TopBar />
        <DashboardDispatch.Provider value={dashboardDispatch}>
          <DashbordContext.Provider value={dashboardContext}>
            <Content />
          </DashbordContext.Provider>
        </DashboardDispatch.Provider>
      </DashboardLayout>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
    </>
  );
};

export default Home;
